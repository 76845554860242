import * as React from "react";
import {Route, Routes} from "react-router-dom";
import {lazy} from "react";
const DeliveryPaymentMain =  lazy(() => import("v2/views/payment/delivery/ui/delivery_payment_main"));
const DeliveryPaymentSelectCoupon = lazy(() => import("v2/views/payment/delivery_coupon/delivery_coupon_payment_main"));
const KidMealMain = lazy(() => import('../v2/views/payment/kid_meal/kid_meal_main'));
const DeliverySelectCoupon = lazy(() => import("../v2/views/payment/refridge/SelectCouponMain"));
const WdgClubMain = lazy(() => import("../v2/views/payment/wdg_club/wdg_club_main"))
const WdgClubSelectCoupon = lazy(() => import("../v2/views/payment/wdg_club/SelectCoupon"));
const SuperDeliveryPaymentMain = lazy(() => import("v2/views/payment/super_delivery/ui/super_delivery_payment_main"));
const EmployeeDiscountMain = lazy(() => import("v2/views/payment/employee_discount/employee_discount_main"));
const PaymentRefundAccountMain = lazy(() => import("v2/views/payment/refund_account/refund_account_main"));

const PaymentRoute = () => {
  return (
    <Routes>
      <Route path="kidMeal/:orderId" element={<KidMealMain/>}/>
      <Route path="kidMeal" element={<KidMealMain/>}/>
      <Route path="refridge/selectCoupon" element={<DeliverySelectCoupon/>} />
      <Route path="wdgClub" element={<WdgClubMain/>}/>
      <Route path="wdgClub/:paidMembershipNumber" element={<WdgClubMain/>}/>
      <Route path="wdgClub/coupons/:paidMembershipNumber" element={<WdgClubSelectCoupon/>}/>
      <Route path="delivery" element={<DeliveryPaymentMain/>}/>
      <Route path="super/delivery" element={<SuperDeliveryPaymentMain/>} />
      <Route path="delivery/coupons" element={<DeliveryPaymentSelectCoupon/>} />
      <Route path="employeeDiscount" element={<EmployeeDiscountMain/>} />
      <Route path="refund-account" element={<PaymentRefundAccountMain/>} />
    </Routes>
  );
}

export default PaymentRoute;
